import api from "@/api"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import styles from "./Documentation.module.scss"
import { Button } from "primereact/button"
import { Badge } from "primereact/badge"
import { ProgressSpinner } from "primereact/progressspinner"
import { onErrorNotification, WorkflowStep, WorkflowTemplate } from "@/types"

import cronstrue from "cronstrue"

interface DocumentationProps {
  templateId?: string
}
interface FileList {
  source?: string
  target?: string
}

const Documentation: React.FC<DocumentationProps> = ({ templateId }) => {
  const [template, setTemplate] = useState<WorkflowTemplate>()
  const [isLoading, setIsLoading] = useState(true)
  const params = useParams()
  const effectiveTemplateId = templateId || params.templateId

  useEffect(() => {
    const loadTemplate = async () => {
      try {
        setIsLoading(true)

        const result = await api.protected.getWorkflowTemplate(effectiveTemplateId!)
        setTemplate(result.template)
      } finally {
        setIsLoading(false)
      }
    }
    if (effectiveTemplateId) loadTemplate()
  }, [effectiveTemplateId])

  if (isLoading) {
    return (
      <div className={styles.loaderContainer}>
        <ProgressSpinner />
      </div>
    )
  }

  if (!template) return null

  const cronJobParser = (cron: string | undefined) => {
    if (!cron) return "No cron job set"
    const parsed = cronstrue.toString(cron)
    return parsed
  }

  const renderAlarmPriority = (template: WorkflowTemplate) => {
    if (!template.onError) return <p>No alarm set!!</p>
    const notify: onErrorNotification[] = template.onError.notify

    return notify.map((notificationType: onErrorNotification) => {
      if (notificationType.type === "pagerDuty") {
        return (
          <div className={styles.alarmPriority}>
            <b>PagerDuty Alarm Priority:</b>
            <div className={`${styles.priorityText} ${styles[notificationType.priority]}`}>
              {notificationType.priority}
            </div>
          </div>
        )
      }
      if (notificationType.type === "email") {
        return (
          <div className={styles.alarmPriority}>
            <b>Notification on Error:</b>
            <div className={`${styles.priorityText} ${styles[notificationType.to]}`}>
              E-mail will be sent to {notificationType.to}
            </div>
          </div>
        )
      }
    })
  }

  const renderSourceOrTarget = (fileLists: FileList[]) => {
    return fileLists
      .map((fileList) => {
        if (fileList.source && !fileList.source.includes("workspace://")) {
          return (
            <p key={fileList.source}>
              <strong>Source:</strong> <br />
              {fileList.source}
            </p>
          )
        }
        if (fileList.target && !fileList.target.includes("workspace://")) {
          return (
            <p key={fileList.target}>
              <strong>Target:</strong> <br />
              {fileList.target}
            </p>
          )
        }
        return null
      })
      .filter(Boolean)
  }


  const renderSteps = () => {
    return template.steps?.map((step: WorkflowStep, index: number) => (
      <div key={index} className={styles.step}>
        <h4>
          <Badge value={index + 1} />
          <span style={{ marginLeft: "10px" }}>{step.name}</span>
        </h4>
        <div className={styles.stepDetails}>
          <p>
            <strong>Handler used in this Step:</strong>
            <br /> {step.handler}
          </p>
          {step.description && (
            <p>
              <strong>Description:</strong> <br /> {step.description}
            </p>
          )}
          {!step.data && (
            <p>
              <strong> Exeption:</strong> <br />
              No default path set. Please check template for the paths set in tenantData!
            </p>
          )}
          {step.data?.fileLists && renderSourceOrTarget(step.data?.fileLists)}
        </div>

        {index < (template.steps?.length ?? 0) - 1 && (
          <div className={styles.arrowContainer}>
            <i className="pi pi-angle-double-down" />
          </div>
        )}
      </div>
    ))
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>{template.displayName}</h3>

        <Button
          size="small"
          label="Edit Template"
          icon="pi pi-pencil"
          onClick={() => (window.location.href = `/app/templates/${template.name}`)}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.basicInfo}>{template.description && <p>{template.description}</p>}</div>

        <div className={styles.steps}>
          <h4>Workflow Steps</h4>
          <div className={styles.info}>
            <p>
              <b>Frequency:</b> {cronJobParser(template.trigger?.cron)}
            </p>
            {renderAlarmPriority(template)}
          </div>
          <div className={styles.diagram}>{renderSteps()}</div>
        </div>
      </div>
    </div>
  )
}

export default Documentation
