import { WorkflowExecution } from "@/types"
import { formatDateTime } from "@/helpers/dateHelpers"
import styles from "./DisplayMetaData.module.scss"


interface DisplayMetaDataProps {
  execData: WorkflowExecution
}

const DisplayMetaData = ({ execData }:DisplayMetaDataProps) => {
  return (
    <>
      <span className={styles.meta}>
        (<span>last scheduler check: {formatDateTime(execData?.lastSchedulerTriggerCheck) || "n.a."} / </span>
        <span>last run: {formatDateTime(execData?.lastWorkflowRun) || "n.a."} / </span>
        <span>by: {execData?.startedBy || "n.a."}</span>)
      </span>
    </>
  )
}

export default DisplayMetaData