import { Navigate, Route, Routes } from "react-router-dom"
import AppLayout from "@/AppLayout"
import TemplateList from "@/components/TemplateList"
import Template from "@/components/Template"
import LogoutConfirmation from "@/components/LogoutConfirmation"
import { LoginCallback } from "@okta/okta-react"
import ProtectedRoute from "@/okta/ProtectedRoute"
import ProtectedApp from "@/ProtectedApp"
import LoginSpinner from "@/components/LoginSpinner"
import WorkflowMonitor from "@/components/workflows/monitor/WorkflowMonitor"
import WorkflowDetails from "@/components/workflows/details/WorkflowDetails"
import WorkflowListPage from "@/components/workflows/list/WorkflowListPage"
import Documentation from "@/components/documentation/Documentation"

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<AppLayout />}>
        <Route path="/" element={<Navigate to="/app/workflows/monitor" replace />} />
        <Route path="/bye" element={<LogoutConfirmation />} />
        <Route path="/loading" element={<LoginSpinner />} />
        <Route path="/implicit/callback" element={<LoginCallback loadingElement={<LoginSpinner />} />} />
        <Route path="app" element={<ProtectedRoute />}>
          <Route path="" element={<ProtectedApp />}>
            <Route path="workflows/monitor" element={<WorkflowMonitor />} />
            <Route path="workflows/list" element={<WorkflowListPage />} />
            <Route path="workflows/:workflowId" element={<WorkflowDetails />} />
            <Route path="templates" element={<TemplateList />} />
            <Route path="templates/:templateId/*" element={<Template />} />
            <Route path="documentation/:templateId/*" element={<Documentation />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  )
}

export default AppRoutes
